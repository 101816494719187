<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" @click.stop>
        <div>
          <div>
            <div style=" flex: 1; text-align: right;">
              <img @click="hide()" style=" opacity: 1; cursor:pointer;width: 24px; height: 24px;"  src="https://gcdn.bionluk.com/site/cicons/close_circle.svg">
            </div>
            <div style="text-align: center; color: #353C48; font-size: 24px">
              Özel Teklif
            </div>
            <div style="text-align: center">
              <img class="mt-2" style="width: 40px; height: 40px" src="https://gcdn.bionluk.com/site/cicons/success_done.svg" />
            </div>
          </div>
          <div style="margin-top: 14px; padding: 24px; background-color: white;">
            <div v-if="offer.type === 'request'" class="mt-1" style="display: flex;">
              <div class="past-offer-label">Alıcı İsteği:</div>
              <div class="past-offer-value" style="color: #4B4F52">{{ offer.request_info.info.title }}</div>
            </div>
            <div class="mt-1" style="display: flex; line-height: 24px;">
              <div class="past-offer-label">Teklif Mesajı:</div>
              <span class="past-offer-value" v-html="offer.description" />
            </div>
            <div style="margin-top: 6px; display: flex;">
              <div class="past-offer-label">İş İlanı:</div>
              <span class="past-offer-value" style="font-size: 13px;">{{ gigTitle }}</span>
            </div>
            <div class="mt-1" style="display: flex;">
              <div class="past-offer-label">Ücret:</div>
              <span class="past-offer-value" style="font-size: 13px;">₺{{ offer.price }}</span>
            </div>
            <div class="mt-1" style="display: flex;">
              <div class="past-offer-label">Süre:</div>
              <span class="past-offer-value" style="font-size: 13px;">{{ offer.duration }} gün</span>
            </div>

          </div>
        </div>
        <div style="background-color: white; align-items: center; align-content: center; text-align: center;">
          <div v-if="offer.info.infoText.length" class="mt-3" style="height: 20px; display: flex; justify-content: center">
            <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-icon"/>
            <div v-if="offer.info.url.length" style="color: #0FBE21; font-size: 15px; line-height: 20px; margin-left: 18px">
              {{ offer.info.infoText | getInfoText('text') }}
              <router-link class="info-text"
                           :to="offer.info.url"
                           @click.native="hide()"
                           style="font-weight: 600;">{{ offer.info.infoText | getInfoText('url') }}</router-link>
            </div>
            <p style="color: #0FBE21; font-size: 15px; line-height: 20px; margin-left: 18px" v-else>{{ offer.info.infoText }}</p>
          </div>
          <button v-if="offer.info.showBuyButton" @click="giveOrder()" class="super-button green-button" style="margin-top: 10px;">
              Hemen Al
            </button>
            <p
              v-if="offer.info.showCancelButton && isCancelRequestDone"
              style="color: #353C48; font-size: 15px; text-align: center; text-decoration: underline; cursor: pointer; margin-top: 26px"
              @click="cancelOffer()"
            >
              İptal Et
            </p>
            <p v-if="offer.info.showCancelButton && !isCancelRequestDone"  style="padding-top: 10px;"> İşlem Yapılıyor...</p>
            <template v-if="offer.isCancelled">
              <div>
                <p style="font-size: 14px; color: #8B95A1; margin-top: 10px">Bu teklif alıcı tarafından kaldırıldı. Teklifini iyileştirerek tekrar teklif verebilirsin</p>
                <button @click="newOfferFromBuyerRequest()" class="super-button green-button" style="margin-top: 20px;">Yeni Teklif Ver
                </button>
              </div>
            </template>
          </div>
      </div>
    </div>
  </transition>
</template>

<script>

  export default {
    name: "src-pages-modals-customOfferDetail-v1",
    data() {
      return {
        offer: null,
        isCancelRequestDone: true,
        selectedGig: null,
        gigTitle: '',
      }
    },

    methods: {
      newOfferFromBuyerRequest(){
        this.EventBus.$emit('openNewOfferFromBuyerRequest', this.offer.request);
      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
      giveOrder() {
        this.api.orders.giveOrder('offer', this.offer.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (this.isMobile) {
                this.$router.push(result.data.redirect_url);
              } else {
              	this.$store.state.clickedFrom = 'custom offer modal'
	              result.data.whereFrom = 'chat';
                this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(result.data), "checkout", "modal_" + this.$store.state.modalComponentMap.checkout);
                this.trackConversionForCheckoutedOrders(result.data.total, "TRY");
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: result}});
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      cancelOffer() {
        this.isCancelRequestDone = false;
        this.api.seller.cancelOffer(this.offer.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.hide();
              this.$toasted.global.infoToast({description: result.message});
              this.isCancelRequestDone  = true;
              this.EventBus.$emit('sendOfferCancelRequest', { offer: this.offer, isCancel: true});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },
    computed: {
      getModalInfo() {
        if (!this.offer) {
          this.offer = this.$store.state.activeModal.info.data;
          this.gigTitle = this.offer.gig_title;
        }
        return true;
      },
      disableToSend() {
        return !this.description || !this.duration || !this.price || !this.selectedGig || !this.gigs.length
      }
    }
  }

</script>

<style scoped lang="scss">

  .modal-container {
    margin-top: 50px;
    width: 698px !important;
    min-height: 380px;
    height: auto;
    max-height: 570px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    padding: 24px;
    overflow-y: scroll;
    position: relative;
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .custom-offer-inner {
    width: 516px;
    height: 262px;
    border-radius: 2px;
    background-color: #f4f5f7;
    padding: 18px 21px 21px 20px;
    margin-top: 32px;
  }

  .custom-offer-header {
    font-size: 32px;
    font-weight: 600;
    color: #2d3640;
  }

  .description-label {
    font-size: 14px;
    color: #2d3640;
    margin-bottom: 10px;
  }

  .description-html {
    width: 525px;
    min-height: 72px;
    height: 90px;
    overflow: scroll;
    max-width: 525px;
  }

  .price-duration-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 22px;
    .price-duration {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 254px;
      height: 80px;
      background-color: #ffffff;
      border: solid 1px #dfe4ec;
    }
  }

  .price-duration-title {
    font-size: 14px;
    color: #8b95a1;
    margin-bottom: 4px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .price-duration-value {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .custom-offer-info {
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
    .info-text {
      font-size: 14px;
      font-style: italic;
      line-height: 1.71;
      color: #8b95a1;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

  }
  .cancel-button {
    cursor: pointer;
    text-decoration: underline;
    padding-top: 10px;
    &:hover {
      font-weight: 600;
    }
  }

  .past-offer-label {
    width: 84px;
    font-size: 13px;
    font-weight: 500;
    color: #4B4F52;
  }

  .past-offer-value {
    font-size: 13px;
    font-weight: 500;
    color: #808896;
    margin-left: 10px;
  }

  .hint-icon /deep/ {
    width: 20px;
    height: 20px;
    g {
      fill: #0FBE21;
    }
  }
</style>
